import { configureStore } from '@reduxjs/toolkit';
import { mirrorNodeApi } from '../APIs/MirrorNode.api';
import { awsApi } from '../APIs/Aws.api';

export const reduxStore = configureStore({
  reducer: {
    [awsApi.reducerPath]: awsApi.reducer,
    [mirrorNodeApi.reducerPath]: mirrorNodeApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(mirrorNodeApi.middleware)
      .concat(awsApi.middleware),
});
