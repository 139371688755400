import React from 'react';
import {
  Anchor,
  Container,
  Group,
  Paper,
  Stack,
  Text,
  LoadingOverlay,
} from '@mantine/core';
import {
  TablerIcon,
  IconBrandDiscord,
  IconBrandLinkedin,
  IconBrandTelegram,
  IconBrandTwitter,
  IconBrandYoutube,
} from '@tabler/icons';
import useStyles from './ProjectStats.styles';

interface IProjectStatsProps {
  isLoading?: boolean;
  social: {
    discord: string;
    twitter: string;
    linkedin: string;
    telegram: string;
    youtube: string;
  };
}

const SocialStats = ({ isLoading, social }: IProjectStatsProps) => {
  const { classes } = useStyles();

  const socialMapping: Array<{
    label: string;
    icon: TablerIcon;
    href: string;
  }> = [
    {
      label: 'Discord',
      icon: IconBrandDiscord,
      href: social.discord,
    },
    {
      label: 'LinkedIn',
      icon: IconBrandLinkedin,
      href: social.linkedin,
    },
    {
      label: 'Telegram',
      icon: IconBrandTelegram,
      href: social.telegram,
    },
    {
      label: 'Twitter',
      icon: IconBrandTwitter,
      href: social.twitter,
    },
    {
      label: 'YouTube',
      icon: IconBrandYoutube,
      href: social.youtube,
    },
  ];

  return (
    <Container p={0}>
      <Stack className={classes.stack} spacing="md">
        <LoadingOverlay visible={!!isLoading} overlayBlur={2} />
        <Paper className={classes.infoCard} withBorder p="md" radius="md">
          <Text className={classes.title} color="dimmed" mb={10}>
            Social
          </Text>

          <Stack spacing={5}>
            {socialMapping.map(socialItem => {
              const { label, href, icon: Icon } = socialItem;

              return href ? (
                <Anchor
                  key={`social-media-item-${label}`}
                  target="_blank"
                  href={href}
                >
                  <Group align="center" spacing={10} noWrap>
                    <Icon className={classes.socialIcon} size={18} />
                    <Text className={classes.value} color="dimmed">
                      {href}
                    </Text>
                  </Group>
                </Anchor>
              ) : (
                <Group
                  key={`social-media-item-${label}`}
                  align="center"
                  spacing={10}
                  noWrap
                >
                  <Icon className={classes.socialIcon} size={18} color="gray" />
                  <Text className={classes.value} color="dimmed">
                    -
                  </Text>
                </Group>
              );
            })}
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
};

export default SocialStats;
