import React from 'react';
import { useEffect, useState } from 'react';
import {
  Header,
  Container,
  Group,
  Burger,
  Image,
  Paper,
  Transition,
  useMantineColorScheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { NavLink, useLocation } from 'react-router-dom';

import useStyles from './Header.style';
import sentinelLogoBlack from '../../assets/sentinelLogo.png';
import sentinelLogoWhite from '../../assets/sentinelLogo_w.png';

export interface IHeaderSimpleProps {
  links: { link: string; label: string }[];
}

export const HeaderSimple = ({ links }: IHeaderSimpleProps) => {
  const location = useLocation();
  const initialLocation = location.pathname.split('/')[1];

  const [opened, { toggle, close }] = useDisclosure(false);
  const [active, setActive] = useState(`/${initialLocation}`);
  const { classes, cx } = useStyles();
  const { colorScheme } = useMantineColorScheme();
  const sentinelLogo =
    colorScheme === 'dark' ? sentinelLogoWhite : sentinelLogoBlack;

  useEffect(() => {
    setActive(`/${location.pathname.split('/')[1]}`);
  }, [location.pathname]);

  const items = links.map(link => {
    return (
      <NavLink
        key={link.label}
        className={cx(classes.link, {
          [classes.linkActive]: active === link.link,
        })}
        to={link.link}
        onClick={e => {
          setActive(link.link);
          close();
        }}
      >
        {link.label}
      </NavLink>
    );
  });

  return (
    <Header height={60}>
      <Container className={classes.header}>
        <Group className={classes.logoContainer} position="apart">
          <NavLink to={'/'}>
            <Image src={sentinelLogo} alt={'Sentinel Logo'} />
          </NavLink>
        </Group>
        <Group spacing={5} className={classes.links}>
          {items}
        </Group>

        <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="sm"
        />
        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {styles => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  );
};
