import React from 'react';

import {
  ColorSchemeProvider,
  ColorScheme,
  MantineProvider,
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import globalStyles from './global.style';
import { reduxStore } from './core/reduxStore';

/** Components */
import LandingPage from './pages/landing-page/LandingPage';
import AppLayout from './components/layout/AppLayout';
import MethodologyPage from './pages/methodology-page/MethodologyPage';
import FaqSimple from './pages/faq-page/FaqPage';
import NotFoundPage from './pages/not-found/NotFoundPage';
import DetailsPage from './pages/details-page/DetailsPage';
import TokensPage from './pages/tokens-page/TokensPage';
import { Provider } from 'react-redux';

function App() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  });

  const toggleTheme = (value?: ColorScheme) => {
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
  };

  return (
    <Provider store={reduxStore}>
      <MantineProvider
        theme={{ colorScheme, globalStyles }}
        withGlobalStyles
        withNormalizeCSS
      >
        <ColorSchemeProvider
          colorScheme={colorScheme}
          toggleColorScheme={toggleTheme}
        >
          <Router>
            <AppLayout>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/faq" element={<FaqSimple />} />
                <Route path="/tokens" element={<TokensPage />} />
                <Route path="/methodology" element={<MethodologyPage />} />
                <Route path="/details/:id" element={<DetailsPage />} />
                <Route path="*" element={<Navigate to="not-found" />} />
                <Route path="not-found" element={<NotFoundPage />} />
              </Routes>
            </AppLayout>
          </Router>
        </ColorSchemeProvider>
      </MantineProvider>
    </Provider>
  );
}

export default App;
