export const METHODOLOGY: any = [
  {
    id: 'teamAndBusiness',
    category: 'Team & Business',
    category_description:
      "Transparency and accountability play a key role in permissionless, customer-facing products. Although privacy is a key valour of the crypto space, statistically, the anonymity of the team must be greatly factored in when interacting with a project's token & dApp.",
    category_weight: '',
    items: [
      {
        question: 'Is the team public and doxxed?',
        answer: ['Yes', 'Partially', 'No'],
        weight: 3,
        average: '',
        id: '1.1',
      },
      {
        question:
          'Is there a project-dedicated development team (in house / on a retainer maintenance contract)?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '1.2',
      },
      {
        question: 'Has the team completed KYC verification successfully?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '1.3',
      },
      {
        question:
          'Is any member of the team a PEP or found on any sanctions lists?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '1.4',
      },
      {
        question: 'Is there an incorporated entity managing the project?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '1.5',
      },
      {
        question: 'Has the the team completed KYB verification successfully?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '1.6',
      },
    ],
  },
  {
    id: 'tokenParameters',
    category: 'Token Parameters',
    category_description:
      "The Hedera Token Service represents a novelty for normal cryptocurrency users of EVM-compatible networks and can serve as a technical hurdle for all users. Understanding the implications of token permissions could help identify a project's intentions & capabilities.",
    category_weight: '',
    items: [
      {
        question: 'Is it a Hedera Token Service (HTS) token?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '2.1',
      },
      {
        question: 'Does the team hold the Admin key for the token?',
        answer: [
          'No',
          'Yes - managed by multisig or contract & justification',
          'Yes - with justification',
          'Yes',
        ],
        weight: 3,
        average: '',
        id: '2.2',
      },
      {
        question: 'Does the team hold the Freeze key for the token?',
        answer: ['No', 'Yes', 'N/A - if reason is provided'],
        weight: 3,
        average: '',
        id: '2.3',
      },
      {
        question: 'Does the team hold the Wipe key for the token?',
        answer: ['No', 'Yes', 'N/A - if reason is provided'],
        weight: 3,
        average: '',
        id: '2.4',
      },
      {
        question: 'Does the team hold the Supply key for the token?',
        answer: ['No', 'Yes', 'N/A - if reason is provided'],
        weight: 3,
        average: '',
        id: '2.5',
      },
      {
        question: 'Does the team hold the Schedule key for the token?',
        answer: ['No', 'Yes', 'N/A - if reason is provided'],
        weight: 3,
        average: '',
        id: '2.6',
      },
      {
        question: 'Does the team hold the Pause key for the token?',
        answer: ['No', 'Yes', 'N/A - if reason is provided'],
        weight: 3,
        average: '',
        id: '2.7',
      },
      {
        question: 'Is the token Max Supply infinite?',
        answer: [
          'Finite',
          'Infinite',
          'N/A - if reason was provided for infinite',
        ],
        weight: 3,
        average: '',
        id: '2.8',
      },
      {
        question:
          'Is the Hedera ERC20 token contract publicly available, audited & with no vulnerabilities?',
        answer: ['Yes', 'Partially', 'No', 'Not applicable'],
        weight: 3,
        average: '',
        id: '2.9',
      },
      {
        question:
          'Does the token use the feeOnTransfer function (taxes for buying / selling)?',
        answer: [
          'No',
          'Less than 10% total taxes(buy + sell)',
          'More than 10% total taxes(buy + sell)',
          'More than 25% total taxes(buy + sell)',
        ],
        weight: 3,
        average: '',
        id: '2.10',
      },
    ],
  },
  {
    id: 'tokenMetrics',
    category: 'Token Metrics',
    category_description:
      'Industry best practices must be considered when rolling out a fungible token, protecting the best interest of projects backers.',
    category_weight: '',
    items: [
      {
        question:
          'Is circulating market cap verifiable on Coingecko / CMC / other aggregators?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '3.1',
      },
      {
        question:
          'Is token distribution fully detailed? Vesting period, cliffs, holding account IDs?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '3.2',
      },
      {
        question:
          'Is token distribution automatic (via smart contracts), as oppose to manual distribution?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '3.3',
      },
      {
        question:
          'Related to tokenomics, please select the option that best classifies your token.',
        answer: [
          'Inflationary',
          'Deflationary',
          'Disinflationary',
          'Mint and burn equilibrium model',
        ],
        weight: 3,
        average: '',
        id: '3.4',
      },
      {
        question:
          'How long is the release schedule? (i.g., time to circulating = max supply)',
        answer: ['> 4 years', '2 - 4 years', '1 - 2 years', '< 1 year'],
        weight: 3,
        average: '',
        id: '3.5',
      },
      {
        question:
          'Is there any token unlocks planned in the first 30 days from TGE?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '3.6',
      },
      {
        question:
          'Are the team & advisors tokens locked up for at least 1 year?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '3.7',
      },
      {
        question: 'Is the token distribution controlled through multisig?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '3.8',
      },
      {
        question:
          'Is there any airdrop of tokens performed in first 30 days from listing? (including giveaways & contests)',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '3.9',
      },
      {
        question:
          'Are there less than 10 individual parties that control together more than 51% of token supply?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '3.10',
      },
      {
        question:
          'Is the token listing rate equal to one of the last funding round?',
        answer: [
          'Yes',
          'No',
          "N/A - the project hasn't undergone any funding round",
        ],
        weight: 3,
        average: '',
        id: '3.11',
      },
      {
        question:
          'Is any consecutive investment round in the token (Private & public) more than double the value of the prior?',
        answer: ['Yes', 'No', 'N/A'],
        weight: 3,
        average: '',
        id: '3.12',
      },
      {
        question:
          'Do the private round(s) amount to more than 20% of supply & is the vesting period spread across multiple years?',
        answer: ['Yes', 'No', 'N/A'],
        weight: 3,
        average: '',
        id: '3.13',
      },
      {
        question:
          'Does the weighted average token buy price across all rounds exceed 33% of the price of the final funding round?',
        answer: ['Yes', 'No', 'N/A'],
        weight: 3,
        average: '',
        id: '3.14',
      },
    ],
  },
  {
    id: 'testingAndAudit',
    category: 'Testing & Audit',
    category_description:
      'Exploits of vulnerabilities pose large threats in open, immutable & permissionless networks. Testing & auditing details the thoroughness of a project when securing its dApp & functionalities.',
    category_weight: '',
    items: [
      {
        question:
          'Are there associated smart contracts/appnets which interact with the token?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '4.1',
      },
      {
        question: 'If yes, are the smart contracts/appnets audited?',
        answer: [
          'Yes',
          'No',
          'N/A - the token or projects has no mainnet smart contract/appnet deployment',
        ],
        weight: 3,
        average: '',
        id: '4.2',
      },
      {
        question:
          'Have the smart contracts/appnets been audited by multiple official entities with open public reports?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '4.3',
      },
      {
        question: "What is the auditor's experience?",
        answer: [
          'More than 100 audits',
          'More than 50 audits',
          'Less than 50 audits',
        ],
        weight: 3,
        average: '',
        id: '4.4',
      },
      {
        question: 'Does the auditor have past Hedera audit experience?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '4.5',
      },
      {
        question: 'Have the audit findings been fixed?',
        answer: [
          'Yes',
          'Only minor remained',
          'Medium remained',
          'Major remained',
          'Critical remained',
          'Audit results unavailable',
        ],
        weight: 3,
        average: '',
        id: '4.6',
      },
      {
        question: 'Is the audit(s) publicly visible?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '4.7',
      },
      {
        question: 'Is the deployed code same as the one that has been audited?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '4.8',
      },
      {
        question:
          'Is the audit addressing the current project mainnet deployment & scope?',
        answer: [
          'Yes to 100%',
          'More than 90%',
          'More than 60%',
          'Less than 60%',
          'N/A',
        ],
        weight: 3,
        average: '',
        id: '4.9',
      },
      {
        question:
          'Is there any Bug bounty program in place, with documentation with proportionate incentives to MC/TVL?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '4.10',
      },
      {
        question:
          'Has there been an Infrastructure Penetration audit & testing been done?',
        answer: [
          'Yes',
          'No',
          'N/A - for appnet projects and not for UI services',
        ],
        weight: 3,
        average: '',
        id: '4.11',
      },
      {
        question: 'Have the Penetration testing findings been fixed?',
        answer: [
          'Yes',
          'Only minor remained',
          'Medium remained',
          'Major remained',
          'Critical remained or if code inaccessible',
          'N/A',
        ],
        weight: 3,
        average: '',
        id: '4.12',
      },
    ],
  },
  {
    id: 'developmentEnvironment',
    category: 'Development environment',
    category_description:
      'Evaluation of the activity and general bookkeeping practices employed by the project.',
    category_weight: '',
    items: [
      {
        question:
          'Does the project have an open-source repository with link available?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '5.1',
      },
      {
        question:
          'How active is the development team on code hosting/version control platforms? (ex: Github, Gitlab, etc.)',
        answer: [
          'At least 1 commit a day',
          'At least 1 commit a week',
          '1 commit a month',
          'Less than 1 commit a month',
          'N/A',
        ],
        weight: 3,
        average: '',
        id: '5.2',
      },
      {
        question:
          'Do the deployed mainnet smart contract/appnets have an Admin key?',
        answer: [
          'Yes',
          'No',
          'N/A - if reason for having an Admin key is provided',
        ],
        weight: 3,
        average: '',
        id: '5.3',
      },
      {
        question: 'Is there a testnet environment available and active?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '5.4',
      },
      {
        question:
          "Are the development team's roles covering the entire responsibility spectrum? (Smart contracts, front end, back end, etc.)",
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '5.5',
      },
      {
        question:
          'Is the deployment environment cloud based, opposite to private hosting providers?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '5.6',
      },
      {
        question:
          'Is the development team contributing to any Hedera Open-source initiatives?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '5.7',
      },
    ],
  },
  {
    id: 'strategyAndDocumentation',
    category: 'Strategy & Documentation',
    category_description:
      'Identifying the clarity of the project’s communication and regulatory savviness and ensuring that no information is left purposefully opaque.',
    category_weight: '',
    items: [
      {
        question:
          'Does the token have utility or is it planned as part of the roadmap?',
        answer: [
          'Utility from TGE',
          'Utility live within 6 months from TGE',
          'Roadmap feature',
          'No utility',
        ],
        weight: 3,
        average: '',
        id: '6.1',
      },
      {
        question: 'Have all pre-liquidity / OTC investors passed KYC checks?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '6.2',
      },
      {
        question: 'Are all token treasuries published?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '6.3',
      },
      {
        question: 'Are all token treasuries managed by a multisig?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '6.4',
      },
      {
        question:
          'Is there a public whitepaper / litepaper / documentation published?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '6.5',
      },
      {
        question:
          'Does the software documentation detail the deployed contracts source code and software architecture?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '6.6',
      },
      {
        question:
          "Are project moderators available across the project's social media channels?(Discord / Telegram / etc.)",
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '6.7',
      },
      {
        question: 'Is the project crosschain?',
        answer: ['Yes', 'No'],
        weight: 3,
        average: '',
        id: '6.8',
      },
      {
        question: 'If cross-chain, which other networks & why?',
        answer: ['Open answer'],
        weight: 3,
        average: '',
        id: '6.9',
      },
    ],
  },
];
