import { createStyles } from '@mantine/core';

const useStyles = createStyles(theme => ({
  stack: {
    position: 'relative',
  },

  infoCard: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  group: {
    width: '100%',
  },

  value: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: theme.fontSizes.xs,

    '&.copy': {
      cursor: 'pointer',
    },
  },

  title: {
    fontWeight: 700,
    textTransform: 'uppercase',
    whiteSpace: 'pre',
    fontSize: theme.fontSizes.xs,
  },

  externalLink: {
    fontSize: theme.fontSizes.sm,
  },

  socialIcon: {
    minWidth: '1rem',
  },
}));

export default useStyles;
