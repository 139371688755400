import React, { useEffect, useState } from 'react';
import ProjectRatings from '../../components/projects-ratings/ProjectsRatings';
import useStyle from './TokensPage.style';
import {
  Container,
  Grid,
  Loader,
  MultiSelect,
  Select,
  TextInput,
  Title,
} from '@mantine/core';
import { CATEGORIES, PROJECT_STATUS } from '../../utils/constants';
import { useGetProjectsQuery } from '../../APIs/Aws.api';
import { SentinelProject } from '../../sentinel.types';

const TokensPage = () => {
  const { cx, classes } = useStyle();
  const { data: projects, isLoading } = useGetProjectsQuery();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string | null>('all');

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const filteredProjects = projects
    ?.filter(project => {
      const projectNameMatch = project.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const categoryMatch =
        selectedCategories.length === 0 ||
        selectedCategories.some(category =>
          project.category?.toLowerCase().includes(category)
        );
      let isActiveMatch = true;

      if (statusFilter === PROJECT_STATUS.INACTIVE) {
        isActiveMatch = project.isActive === false;
      } else if (statusFilter === PROJECT_STATUS.ACTIVE) {
        isActiveMatch = project.isActive === undefined;
      }

      return projectNameMatch && categoryMatch && isActiveMatch;
    })
    .sort((a, b) => a.name.localeCompare(b.name)) as SentinelProject[];

  return isLoading ? (
    <Loader className={classes.loader} />
  ) : (
    <div className={classes.outer}>
      <Container className={classes.container}>
        <div className={cx(classes.inner, 'column')}>
          <Title className={classes.title}>
            <span>Projects</span>
          </Title>
          <Grid>
            <Grid.Col span={6}>
              <MultiSelect
                label="Category"
                placeholder="Select category"
                data={CATEGORIES.map(category => ({
                  value: category.toLowerCase(),
                  label: category.toLowerCase(),
                }))}
                value={selectedCategories}
                onChange={setSelectedCategories}
                clearable
              />
            </Grid.Col>

            <Grid.Col span={4}>
              <TextInput
                label="Search"
                placeholder="Search by project name..."
                onChange={e => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
            </Grid.Col>

            <Grid.Col span={2}>
              <Select
                label="Status"
                placeholder="Filter by status"
                data={[
                  { value: 'all', label: 'All' },
                  { value: PROJECT_STATUS.ACTIVE, label: 'Active' },
                  { value: PROJECT_STATUS.INACTIVE, label: 'Inactive' },
                ]}
                value={statusFilter}
                onChange={setStatusFilter}
                clearable={false}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              {filteredProjects?.length > 0 && (
                <ProjectRatings data={filteredProjects} />
              )}
            </Grid.Col>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default TokensPage;
