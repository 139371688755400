import React from 'react';

import { Stack, Grid, useMantineTheme } from '@mantine/core';
import useStyles from './MethodologyTable.style';
import withThemeColorAnswers from '../../data/answers-color-schema';

interface IMethodologyTableProps {
  className: string;
  data: {
    category_description: string;
    category: string;
    items: [
      {
        question: string;
        answer: string;
      },
    ];
  };
}

const MethodologyTable = ({ className, data }: IMethodologyTableProps) => {
  const { cx, classes } = useStyles();
  const theme = useMantineTheme();

  const grid = data.items.map((element: any, index: number) => {
    return (
      <Grid
        key={`${data.category}-${index}`}
        className={classes.mCategory}
        grow
      >
        <Grid.Col className={classes.row} span={8}>
          <div className={classes.item}>
            <p>{element.question}</p>
          </div>
        </Grid.Col>

        <Grid.Col className={cx(classes.row, 'answers')} span={4}>
          <Stack spacing={0}>
            {element.answer.map((answer: any, index: number) => {
              const { answerColors, defaultColors } =
                withThemeColorAnswers(theme);
              const color =
                answerColors[element.id][answer] || defaultColors.DEFAULT;

              return (
                <div
                  key={`${data.category}-answer-${index}`}
                  className={classes.answer}
                  style={{ borderLeft: `4px solid ${color}` }}
                >
                  <p>{answer}</p>
                </div>
              );
            })}
          </Stack>
        </Grid.Col>
      </Grid>
    );
  });

  return (
    <div className={cx(className, classes.table)}>
      <h2 className={classes.header}>{data.category}</h2>
      <p className={classes.description}>{data.category_description}</p>
      {grid}
    </div>
  );
};

export default MethodologyTable;
