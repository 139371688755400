import { ProjectParameters, TokenInfo } from '../sentinel.types';

const getTokenParams = (
  tokenInfo: TokenInfo,
  staticTokenParameters: ProjectParameters[]
) => {
  let feeDescriptions: string[] = [];

  if (tokenInfo.custom_fees.fixed_fees) {
    for (const fee of tokenInfo.custom_fees.fixed_fees) {
      feeDescriptions.push(
        `Fixed fee: ${fee.amount} (Collector: ${fee.collector_account_id})`
      );
    }
  }

  if (tokenInfo.custom_fees.fractional_fees) {
    for (const fee of tokenInfo.custom_fees.fractional_fees) {
      const feePercentage =
        (fee.amount.numerator / fee.amount.denominator) * 100;
      feeDescriptions.push(
        `Fractional fee: ${feePercentage}%, Collector: ${fee.collector_account_id}, `
      );
    }
  }

  if (tokenInfo.custom_fees.royalty_fees) {
    for (const fee of tokenInfo.custom_fees.royalty_fees) {
      const feePercentage =
        (fee.amount.numerator / fee.amount.denominator) * 100;
      feeDescriptions.push(
        `Royalty fee: ${feePercentage} Collector: ${fee.collector_account_id}`
      );
    }
  }

  let feeDescriptionString =
    feeDescriptions.length > 0
      ? feeDescriptions.join('; ')
      : 'No custom fees associated with this token.';

  const mirrorAnswerMapping: { [key: string]: Partial<ProjectParameters> } = {
    '2.1': {
      answer: tokenInfo.tokenId ? 'Yes' : 'No',
    },
    '2.2': {
      answer: tokenInfo.adminKey ? 'Yes' : 'No',
    },
    '2.3': {
      answer: tokenInfo.freezeKey ? 'Yes' : 'No',
    },
    '2.4': {
      answer: tokenInfo.wipeKey ? 'Yes' : 'No',
    },
    '2.5': {
      answer: tokenInfo.supplyKey ? 'Yes' : 'No',
    },
    '2.6': {
      answer: tokenInfo.feeScheduleKey ? 'Yes' : 'No',
    },
    '2.7': {
      answer: tokenInfo.pauseKey ? 'Yes' : 'No',
    },
    '2.8': {
      answer: tokenInfo.supplyType,
    },
    '2.9': {
      answer: tokenInfo.tokenId ? 'Not applicable' : '',
    },
    '2.10': {
      description: feeDescriptionString,
    },
  };

  return staticTokenParameters.map(question => {
    return {
      ...question,
      ...mirrorAnswerMapping[question.id],
    };
  });
};

export { getTokenParams };
