import React, { useState } from 'react';
import {
  Anchor,
  Container,
  Group,
  Paper,
  Stack,
  Text,
  LoadingOverlay,
  Alert,
} from '@mantine/core';
import {
  IconExternalLink,
  IconInfoCircle,
  IconAlertCircle,
} from '@tabler/icons';
import useStyles from './ProjectStats.styles';
import { TokenInfo } from '../../../sentinel.types';

interface IProjectStatsProps {
  isLoading?: boolean;
  isActive?: boolean;
  data: {
    token: {
      id: string;
      cmc: string;
      coinGecko: string;
    };
    tokenInfo?: TokenInfo;
  };
}

const ProjectStats = ({
  isLoading,
  isActive,
  data: { token, tokenInfo },
}: IProjectStatsProps) => {
  const { classes } = useStyles();
  const [showPublicationDisclaimer, setShowPublicationDisclaimer] =
    useState<boolean>(true);

  const { name, symbol } = tokenInfo || {};

  return (
    <Container p={0}>
      <Stack className={classes.stack} spacing="md">
        <LoadingOverlay visible={!!isLoading} overlayBlur={2} />

        {showPublicationDisclaimer && (
          <Alert
            title="Info"
            icon={<IconInfoCircle size="1rem" />}
            onClose={() => setShowPublicationDisclaimer(false)}
            closeButtonLabel="Close disclaimer"
            withCloseButton
          >
            <Text size={12}>
              The publication of this report does not represent an endorsement
              of any kind. This is a free & open community resource and is
              intended solely for educational purposes and to encourage readers
              to do their own research (DYOR) before interacting with a project
              or token.
            </Text>
          </Alert>
        )}

        {isActive === false && (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Alert"
            color="red"
          >
            <Text size={12}>
              This project was confirmed to be a SCAM and has Rugpulled. Please
              take note of project parameters and details to help identify
              warning signals in future projects.
            </Text>
          </Alert>
        )}

        <Paper className={classes.infoCard} withBorder p="md" radius="md">
          <Stack spacing={5}>
            <Group className={classes.group} align="center" spacing="xs" noWrap>
              <Text className={classes.title} color="dimmed">
                Token ID:
              </Text>

              <Anchor
                className={classes.externalLink}
                href={`https://hashscan.io/mainnet/token/${token.id}`}
                color="dimmed"
              >
                <Group className={classes.group} align="center" spacing={5}>
                  <Text className={classes.value}>{token.id}</Text>
                  <IconExternalLink size={16} />
                </Group>
              </Anchor>
            </Group>

            <Group className={classes.group} align="center" spacing="xs" noWrap>
              <Text className={classes.title} color="dimmed">
                Name:
              </Text>
              <Text className={classes.value}>{name ? name : '-'}</Text>
            </Group>

            <Group className={classes.group} align="center" spacing="xs" noWrap>
              <Text className={classes.title} color="dimmed">
                Symbol:
              </Text>
              <Text className={classes.value}>{symbol ? symbol : '-'}</Text>
            </Group>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
};

export default ProjectStats;
