import React from 'react';
import { AppShell } from '@mantine/core';

import { HeaderSimple } from '../header/Header';
import FooterSimple from '../footer/Footer';
import { HEADER_LINKS, FOOTER_LINKS } from '../../utils/constants';

export interface IAppLayoutProps extends React.HTMLAttributes<HTMLElement> {}

function AppLayout(props: IAppLayoutProps) {
  const { children } = props;

  return (
    <AppShell
      padding="md"
      header={<HeaderSimple links={HEADER_LINKS.links} />}
      footer={<FooterSimple socialMediaLinks={FOOTER_LINKS.socialMediaLinks} />}
      styles={theme => ({
        main: {
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      {children}
    </AppShell>
  );
}

export default AppLayout;
