import { createStyles } from '@mantine/core';

const useStyles = createStyles(theme => ({
  container: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    borderRadius: '15px',
  },
  questionWrapper: {
    flexWrap: 'nowrap',

    [theme.fn.smallerThan('sm')]: {
      flexWrap: 'wrap',
    },
  },
  item: {
    height: '100%',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.025)',
    background:
      theme.colorScheme === 'dark'
        ? theme.colors.gray[9]
        : theme.colors.gray[0],
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.gray[0]
        : theme.colors.gray[8],
    fontSize: theme.fontSizes.xs,
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',

    '.question': {
      flex: '1 1 78%',
      padding: theme.spacing.sm,

      '.description': {
        fontSize: theme.fontSizes.xs,
        color:
          theme.colorScheme === 'dark'
            ? theme.colors.blue[3]
            : theme.colors.gray[5],
        fontStyle: 'italic',
        wordBreak: 'break-all',

        '& > a': {
          color:
            theme.colorScheme === 'dark'
              ? theme.colors.blue[3]
              : theme.colors.blue[5],
          textDecoration: 'none',
        },
      },

      '.status': {
        marginTop: theme.spacing.lg,
      },
    },

    '.answer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1 1 22%',
      padding: '0.5rem',
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.gray[8]
          : theme.colors.gray[1],

      '& > p': {
        textAlign: 'center',
        fontWeight: 400,
        color:
          theme.colorScheme === 'dark'
            ? theme.colors.gray[4]
            : theme.colors.gray[7],
      },
    },
  },
  answer: {
    fontSize: theme.fontSizes.md,
    paddingTop: '15px',
  },
  table: {
    maxWidth: '850px',
    width: '100%',
    margin: 'auto',
    marginBottom: '10px',

    [theme.fn.smallerThan('lg')]: {
      maxWidth: 'unset',
    },
  },
  header: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.gray[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.xl,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing.xl,

    [theme.fn.smallerThan('xs')]: {
      fontSize: theme.fontSizes.md,
    },
  },
}));

export default useStyles;
