import { createStyles } from '@mantine/core';

const useStyles = createStyles(theme => ({
  ratingsTable: {
    thead: {
      tr: {
        th: {
          fontSize: theme.fontSizes.sm,
        },
      },
    },

    tbody: {
      tr: {
        '.name, .category, .token-type': {
          a: {
            fontSize: theme.fontSizes.sm,
          },
        },

        '.category, .token-type': {
          a: {
            pointerEvents: 'none',
          },
        },

        '.review-date': {
          '& > *': {
            fontSize: theme.fontSizes.sm,
          },
        },

        '&:hover': {
          cursor: 'pointer',
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
              : theme.colors[theme.primaryColor][0],
        },
      },
    },
  },

  infoIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  tooltipLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '.title': {
      fontWeight: 700,
    },

    '.local-date': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      span: {
        fontSize: theme.fontSizes.xs,
        wordBreak: 'break-all',
      },
    },
  },

  badge: {
    marginRight: '8px',
  },
}));

export default useStyles;
