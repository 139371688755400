import { MantineTheme } from '@mantine/core';

const globalStyles = (theme: MantineTheme) => ({
  '::-webkit-scrollbar': {
    width: '5px',
  },

  '::-webkit-scrollbar-track': {
    background: theme.colors.gray[2],
  },

  '::-webkit-scrollbar-thumb': {
    background: theme.colors.gray[5],
  },

  '::-webkit-scrollbar-thumb:hover': {
    background: theme.colors.gray[6],
  },
});

export default globalStyles;
