import { createStyles } from '@mantine/core';

const useStyles = createStyles(theme => ({
  container: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    borderRadius: '15px',
  },
  item: {
    height: '100%',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.025)',
    fontSize: theme.fontSizes.xs,
  },

  table: {
    width: '100%',
    margin: 'auto',
    marginBottom: '10px',

    [theme.fn.smallerThan('lg')]: {
      maxWidth: 'unset',
    },
  },
  header: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.gray[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.xl,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing.xl,

    [theme.fn.smallerThan('xs')]: {
      fontSize: theme.fontSizes.md,
    },
  },
  tableHeader: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  tableColumn: {
    position: 'relative',
  },

  infoCard: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  group: {
    width: '100%',
  },

  value: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.gray[0]
        : theme.colors.gray[7],
    '&.copy': {
      cursor: 'pointer',
    },
    [theme.fn.smallerThan('xs')]: {
      fontSize: theme.fontSizes.xs,
    },
  },
  anchor: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.gray[0]
        : theme.colors.gray[7],
  },
  icon: {
    [theme.fn.smallerThan('xs')]: {
      width: 16,
      height: 16,
    },
  },

  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default useStyles;
