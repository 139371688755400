import { createStyles } from '@mantine/core';

const useStyles = createStyles(theme => ({
  mCategory: {
    marginBottom: theme.spacing.md,
  },

  item_descrption: {
    fontSize: theme.fontSizes.xs,
    padding: `0 ${theme.spacing.md}px`,
    margin: 0,

    [theme.fn.smallerThan('xs')]: {
      padding: 0,
    },
  },
  item: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 600,
    padding: `0 ${theme.spacing.md}px`,
    marginTop: 'auto',
    marginBottom: 'auto',
    whiteSpace: 'pre-line',

    [theme.fn.smallerThan('xs')]: {
      padding: 0,
      fontSize: theme.fontSizes.xs,
    },
  },
  answer: {
    fontSize: theme.fontSizes.sm,
    margin: 0,

    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.fn.rgba(
        theme.colorScheme === 'dark'
          ? theme.colors.gray[7]
          : theme.colors.gray[4],
        0.5
      )}`,
    },

    p: {
      wordBreak: 'break-word',
      padding: '0.5rem 0.5rem 0.5rem 1rem',
    },

    [theme.fn.smallerThan('xs')]: {
      fontSize: theme.fontSizes.xs,
    },
  },
  row: {
    background:
      theme.colorScheme === 'dark'
        ? theme.colors.gray[9]
        : theme.colors.gray[2],
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.gray[0]
        : theme.colors.gray[8],
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px 0 0 10px',

    '&.answers': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.gray[8]
          : theme.colors.gray[3],
      padding: 0,
      borderRadius: '0 10px 10px 0',
    },
  },
  table: {
    maxWidth: '850px',
    width: '100%',
    margin: 'auto',
    marginBottom: '40px',

    [theme.fn.smallerThan('xs')]: {
      width: '95%',
    },
  },
  header: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontSize: theme.fontSizes.xl,
    fontWeight: 600,
    margin: `20px 0 40px 0`,
    display: 'flex',
    justifyContent: 'center',

    [theme.fn.smallerThan('xs')]: {
      fontSize: theme.fontSizes.md,
    },
  },
  description: {
    marginBottom: 48,
    fontSize: theme.fontSizes.sm,
    textAlign: 'center',
  },
}));

export default useStyles;
