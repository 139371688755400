import { MantineTheme } from '@mantine/core';

interface IAnswersColors {
  GREEN: string;
  LIME: string;
  YELLOW: string;
  LIGHT_ORANGE: string;
  ORANGE: string;
  RED: string;
  DEFAULT: string;
}

const composeAnswersColors = (colors: IAnswersColors): any => ({
  '1.1': {
    Yes: colors.GREEN,
    Partially: colors.YELLOW,
    No: colors.RED,
  },
  '1.2': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '1.3': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '1.4': {
    Yes: colors.RED,
    No: colors.GREEN,
  },
  '1.5': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '1.6': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '2.1': {
    Yes: colors.GREEN,
    No: colors.ORANGE,
  },
  '2.2': {
    No: colors.GREEN,
    'Yes - managed by multisig or contract & justification': colors.YELLOW,
    'Yes - with justification': colors.ORANGE,
    Yes: colors.ORANGE,
  },
  '2.3': {
    Yes: colors.ORANGE,
    No: colors.GREEN,
  },
  '2.4': {
    Yes: colors.ORANGE,
    No: colors.GREEN,
  },
  '2.5': {
    Yes: colors.ORANGE,
    No: colors.GREEN,
  },
  '2.6': {
    Yes: colors.ORANGE,
    No: colors.GREEN,
  },
  '2.7': {
    Yes: colors.ORANGE,
    No: colors.GREEN,
  },
  '2.8': {
    FINITE: colors.GREEN,
    INFINITE: colors.ORANGE,
  },
  '2.9': {
    Yes: colors.GREEN,
    Partially: colors.ORANGE,
    No: colors.RED,
    'Not applicable': colors.DEFAULT,
  },
  '2.10': {
    No: colors.GREEN,
    'Less than 10% total taxes(buy + sell)': colors.YELLOW,
    'More than 10% total taxes(buy + sell)': colors.ORANGE,
    'More than 25% total taxes(buy + sell)': colors.ORANGE,
  },
  '3.1': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '3.2': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '3.3': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '3.4': {
    Inflationary: colors.DEFAULT,
    Deflationary: colors.DEFAULT,
    Disinflationary: colors.DEFAULT,
    'Mint and burn equilibrium model': colors.DEFAULT,
  },
  '3.5': {
    '> 4 years': colors.GREEN,
    '2 - 4 years': colors.YELLOW,
    '1 - 2 years': colors.ORANGE,
    '< 1 year': colors.RED,
  },
  '3.6': {
    Yes: colors.RED,
    No: colors.GREEN,
  },
  '3.7': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '3.8': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '3.9': {
    Yes: colors.DEFAULT,
    No: colors.DEFAULT,
  },
  '3.10': {
    Yes: colors.RED,
    No: colors.GREEN,
  },
  '3.11': {
    Yes: colors.DEFAULT,
    No: colors.DEFAULT,
  },
  '3.12': {
    Yes: colors.DEFAULT,
    No: colors.DEFAULT,
  },
  '3.13': {
    Yes: colors.DEFAULT,
    No: colors.DEFAULT,
  },
  '3.14': {
    Yes: colors.DEFAULT,
    No: colors.DEFAULT,
  },
  '4.1': {
    Yes: colors.DEFAULT,
    No: colors.DEFAULT,
  },
  '4.2': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '4.3': {
    Yes: colors.GREEN,
    No: colors.DEFAULT,
  },
  '4.4': {
    'More than 100 audits': colors.GREEN,
    'More than 50 audits': colors.YELLOW,
    'Less than 50 audits': colors.ORANGE,
  },
  '4.5': {
    Yes: colors.GREEN,
    No: colors.YELLOW,
  },
  '4.6': {
    Yes: colors.GREEN,
    'Only minor remained': colors.LIME,
    'Medium remained': colors.YELLOW,
    'Major remained': colors.ORANGE,
    'Critical remained': colors.RED,
    'Audit results unavailable': colors.RED,
  },
  '4.7': {
    Yes: colors.GREEN,
    No: colors.ORANGE,
  },
  '4.8': {
    Yes: colors.GREEN,
    No: colors.YELLOW,
  },
  '4.9': {
    'Yes to 100%': colors.GREEN,
    'More than 90%': colors.LIME,
    'More than 60%': colors.YELLOW,
    'Less than 60%': colors.LIGHT_ORANGE,
  },
  '4.10': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '4.11': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '4.12': {
    Yes: colors.GREEN,
    'Only minor remained': colors.LIME,
    'Medium remained': colors.LIGHT_ORANGE,
    'Major remained': colors.ORANGE,
    'Critical remained or if code inaccessible': colors.RED,
  },
  '5.1': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '5.2': {
    'At least 1 commit a day': colors.GREEN,
    'At least 1 commit a week': colors.YELLOW,
    '1 commit a month': colors.ORANGE,
    'Less than 1 commit a month': colors.RED,
  },
  '5.3': {
    Yes: colors.YELLOW,
    No: colors.GREEN,
  },
  '5.4': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '5.5': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '5.6': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '5.7': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '6.1': {
    'Utility from TGE': colors.GREEN,
    'Utility live within 6 months from TGE': colors.YELLOW,
    'Roadmap feature': colors.ORANGE,
    '"No utility': colors.RED,
  },
  '6.2': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '6.3': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '6.4': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '6.5': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '6.6': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '6.7': {
    Yes: colors.GREEN,
    No: colors.RED,
  },
  '6.8': {
    Yes: colors.DEFAULT,
    No: colors.DEFAULT,
  },
  '6.9': {
    Yes: colors.DEFAULT,
    No: colors.DEFAULT,
  },
});

const withThemeColorAnswers = (theme: MantineTheme) => {
  const isDark = theme.colorScheme === 'dark';

  const colors: IAnswersColors = {
    GREEN: isDark ? theme.colors.green[8] : theme.colors.green[4],
    LIME: isDark ? theme.colors.lime[7] : theme.colors.lime[3],
    YELLOW: isDark ? theme.colors.yellow[5] : theme.colors.yellow[2],
    LIGHT_ORANGE: isDark ? theme.colors.yellow[7] : theme.colors.yellow[6],
    ORANGE: theme.colors.orange[6],
    RED: isDark ? theme.colors.red[8] : theme.colors.red[5],
    DEFAULT: isDark ? theme.colors.gray[7] : theme.colors.gray[4],
  };

  return {
    answerColors: composeAnswersColors(colors),
    defaultColors: colors,
  };
};

export default withThemeColorAnswers;
