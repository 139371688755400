import React from 'react';
import { Badge, Text, ActionIcon, Popover, Center } from '@mantine/core';
import { IconFile, IconNote, IconWallet } from '@tabler/icons';

export interface AccountDetails {
  key: {
    _type: string;
    key: string;
  };
  memo: string;
}

export const renderMemoTooltip = (memo: string) => {
  if (
    memo === 'auto-created account' ||
    memo === 'lazy-created account' ||
    memo === ''
  ) {
    return null;
  }

  return (
    <Popover width={150} position="right" withArrow shadow="md">
      <Popover.Target>
        <ActionIcon variant="transparent">
          <IconNote />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Text size="xs">{memo}</Text>
      </Popover.Dropdown>
    </Popover>
  );
};

export const getIcon = (accountDetails: AccountDetails) => {
  if (!accountDetails) return null;

  if (!accountDetails.key)
    return (
      <Badge
        variant="light"
        size="xs"
        color="blue"
        radius="xs"
        leftSection={
          <Center>
            <IconFile size={14} />
          </Center>
        }
      >
        <Text>Contract</Text>
      </Badge>
    );

  if (accountDetails.key._type === 'ProtobufEncoded') {
    return (
      <Badge
        variant="light"
        size="xs"
        color="blue"
        radius="xs"
        leftSection={
          <Center>
            <IconWallet size={14} />
          </Center>
        }
      >
        <Text>Multisig</Text>
      </Badge>
    );
  }
};

export const formatBalance = (balance: number, decimals: number) => {
  return (balance / Math.pow(10, decimals)).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
