import React, { useEffect } from 'react';
import MethodologyTable from '../../components/methodology-table/MethodologyTable';
import useStyles from './MethodologyPage.styles';
import { METHODOLOGY } from '../../data/methodology';

const MethodologyPage = () => {
  const { classes } = useStyles();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className={classes.outer}>
      {METHODOLOGY.map((methodologyCategory: any, index: number) => {
        return (
          <MethodologyTable
            key={index}
            className={methodologyCategory.category.replaceAll(' ', '_')}
            data={methodologyCategory}
          />
        );
      })}
    </div>
  );
};

export default MethodologyPage;
