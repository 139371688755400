import { createStyles } from '@mantine/core';

const useStyles = createStyles(theme => ({
  card: {
    position: 'relative',
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    borderRadius: '15px',
  },

  coverPlaceholder: {
    minHeight: 250,
  },

  logoWrapper: {
    position: 'absolute',
    left: '1.4rem',
    top: '6.8rem',

    '.logo-container': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.black : theme.white,
      borderRadius: '15px',
      overflow: 'hidden',
    },

    '.frame': {
      position: 'absolute',
      inset: 0,
      border: `3px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white}`,
      outline: `3px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white}`,
      borderRadius: '15px',
    },
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing.sm}px ${theme.spacing.lg}px`,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  title: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.gray[0]
        : theme.colors.gray[7],
    fontWeight: 600,
    fontSize: theme.fontSizes.sm * 2,
    display: 'flex',
    justifyContent: 'center',

    '.icon': {
      marginBottom: '0.6rem',
    },

    [theme.fn.smallerThan('xs')]: {
      fontSize: theme.fontSizes.md,
    },
  },
}));

export default useStyles;
