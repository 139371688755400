type ProjectRatings = {
  id: string;
  answer: string;
  description?: string;
};

export type SentinelProject = {
  isActive?: boolean;
  visible?: boolean;
  projectId: string;
  name: string;
  logo?: string;
  cover?: string;
  description: string;
  website?: string;
  category?: string;
  reviewDate: number;
  token: {
    id: string;
    coinGecko: string;
    cmc: string;
  };
  social: {
    discord: string;
    twitter: string;
    linkedin: string;
    telegram: string;
    youtube: string;
  };
  teamAndBusiness: Array<ProjectRatings>;
  tokenParameters: Array<ProjectRatings>;
  testingAndAudit: Array<ProjectRatings>;
  developmentEnvironment: Array<ProjectRatings>;
  strategyAndDocumentation: Array<ProjectRatings>;
};

export type TokenInfo = {
  tokenId: string | undefined;
  name: string;
  symbol: string;
  adminKey: {
    key: string;
    type: string;
  } | null;
  freezeKey: {
    key: string;
    type: string;
  } | null;
  wipeKey: {
    key: string;
    type: string;
  } | null;
  supplyKey: {
    key: string;
    type: string;
  } | null;
  feeScheduleKey: {
    key: string;
    type: string;
  } | null;
  pauseKey: {
    key: string;
    type: string;
  } | null;
  supplyType: string;
  totalSupply: number;
  decimals: number;
  isActive: boolean | undefined;
  custom_fees: {
    royalty_fees: Array<{
      amount: {
        numerator: number;
        denominator: number;
      };
      collector_account_id: string;
    }>;
    fractional_fees: Array<{
      amount: {
        numerator: number;
        denominator: number;
      };
      maximum: number;
      minimum: number;
      denominator: number;
      collector_account_id: string;
    }>;
    fixed_fees: Array<{
      amount: number;
      collector_account_id: string;
    }>;
  };
};

export enum ReviewAnswerStatus {
  UNDER_REVIEW,
  REVIEW_COMPLETED,
  REVIEW_FAILED,
  FETCHED,
}

export interface TokenParameterItem {
  question: string;
  answer: string[] | string; // Assuming answers could be an array or a single string
  weight: number;
  average: string;
  id: string;
}

export interface TokenBalances {
  timestamp: string;
  balances: Array<{
    account: string;
    balance: number;
    decimals: number;
  }>;
  links: {
    next: string;
  };
}

export interface ProjectParameters {
  id: string;
  answer: string;
  description: string;
  status: number;
}
