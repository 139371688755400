import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Container, Text, Accordion } from '@mantine/core';

import useStyles from './FaqPage.style';
import { FAQ_QUESTIONS } from '../../utils/constants';

const FaqSimple = () => {
  const { classes } = useStyles();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Container size="sm" className={classes.wrapper}>
      <div>
        <Text align="center" className={classes.title}>
          Frequently Asked Questions
        </Text>

        <Accordion variant="separated">
          {FAQ_QUESTIONS &&
            FAQ_QUESTIONS.map(faq_question => {
              return (
                <Accordion.Item
                  className={classes.item}
                  value={faq_question.id}
                  key={faq_question.id}
                >
                  <Accordion.Control>
                    <ReactMarkdown
                      className={classes.question}
                      children={faq_question.question}
                    />
                  </Accordion.Control>
                  <Accordion.Panel>
                    <ReactMarkdown
                      className={classes.answer}
                      children={faq_question.answer}
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              );
            })}
        </Accordion>
      </div>
    </Container>
  );
};

export default FaqSimple;
