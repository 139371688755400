import React from 'react';
import {
  Footer,
  Group,
  ActionIcon,
  UnstyledButton,
  Image,
  useMantineColorScheme,
} from '@mantine/core';
import { TablerIcon, IconSun, IconMoonStars } from '@tabler/icons';
import useStyles from './Footer.style';
import buidlerLabsLogo from '../../assets/builderLabsLogo.png';
import buidlerLabsLogoWhite from '../../assets/builderLabsLogo_w.png';

interface ISocialMediaLink {
  href: string;
  icon: TablerIcon;
}

export interface IFooterSimpleProps {
  socialMediaLinks: Array<ISocialMediaLink>;
}

export function FooterSimple({ socialMediaLinks }: IFooterSimpleProps) {
  const { classes } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const logo = colorScheme === 'dark' ? buidlerLabsLogoWhite : buidlerLabsLogo;

  return (
    <Footer height={80} className={classes.footer}>
      <div className={classes.inner}>
        <Group className={classes.logoContainer}>
          <Image src={logo} alt={'Work in progress image'} />
        </Group>

        <Group spacing="xs" position="right" noWrap>
          {socialMediaLinks.map(
            ({ href, icon: Icon }: ISocialMediaLink, index: number) => {
              return (
                <UnstyledButton
                  key={`social-media-link-${index}`}
                  component="a"
                  target="_blank"
                  href={href}
                >
                  <ActionIcon size="lg" variant="default" radius="xl">
                    <Icon size={18} stroke={1.5} />
                  </ActionIcon>
                </UnstyledButton>
              );
            }
          )}

          <ActionIcon
            onClick={() => toggleColorScheme()}
            size="lg"
            sx={theme => ({
              marginLeft: theme.spacing.sm,
              backgroundColor:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[6]
                  : theme.colors.gray[0],
              color:
                theme.colorScheme === 'dark'
                  ? theme.colors.yellow[4]
                  : theme.colors.blue[6],
            })}
          >
            {colorScheme === 'dark' ? (
              <IconSun size={18} />
            ) : (
              <IconMoonStars size={18} />
            )}
          </ActionIcon>
        </Group>
      </div>
    </Footer>
  );
}

export default FooterSimple;
