import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  container: {
    maxWidth: 1100,
    padding: 0,
    marginTop: `0 !important`,
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default useStyles;
