import React from 'react';
import ReactMarkdown from 'react-markdown';

import { Grid, Card, Text, Badge, useMantineTheme } from '@mantine/core';
import useStyles from './MethodologyCard.style';
import Linkify from 'react-linkify';
import withThemeColorAnswers from '../../../data/answers-color-schema';
import { PROJECT_ANSWER_STATUS } from '../../../utils/constants';
import { ReviewAnswerStatus } from '../../../sentinel.types';

interface IMethodologyCardProps {
  data: {
    id: string;
    category_description: string;
    category: string;
    items: [
      {
        question: string;
        answer: string;
      },
    ];
  };

  answers: Array<{
    id: string;
    answer: string;
    description: string;
    status: number;
  }>;
  tokenParamsLoaded: boolean;
}

const MethodologyCard = ({
  data,
  answers,
  tokenParamsLoaded,
}: IMethodologyCardProps) => {
  const { cx, classes } = useStyles();
  const theme = useMantineTheme();
  const { answerColors, defaultColors } = withThemeColorAnswers(theme);

  const grid = data.items.map((element: any, index: number) => {
    const answerId: string = answers[index].id;
    const answer: string = answers[index].answer;
    const description: string = answers[index].description;
    const statusId: number = answers[index].status;
    const status = PROJECT_ANSWER_STATUS[statusId];
    const MIRROR = PROJECT_ANSWER_STATUS[ReviewAnswerStatus.FETCHED];
    const answerBgColor: string =
      answerColors[answerId][answer] || defaultColors.DEFAULT;

    return (
      <Grid
        key={`${data.category}-${index}`}
        className={classes.questionWrapper}
        grow
      >
        <Grid.Col xs={12} sm={8} lg={8}>
          <div className={classes.item}>
            <div className="question">
              <ReactMarkdown children={element.question} />
              <Linkify>
                <Text className="description">{description}</Text>
              </Linkify>
              <div className="status">
                <Badge
                  style={{ marginRight: '0.4rem' }}
                  variant="dot"
                  color={status.color}
                >
                  {status.label}
                </Badge>
                {data.id === 'tokenParameters' && tokenParamsLoaded && (
                  <Badge variant="dot" color={MIRROR.color}>
                    {MIRROR.label}
                  </Badge>
                )}
              </div>
            </div>
            <div
              className={cx('answer')}
              style={{ borderBottom: `6px solid ${answerBgColor}` }}
            >
              <p>{answer}</p>
            </div>
          </div>
        </Grid.Col>
      </Grid>
    );
  });

  return (
    <Card withBorder p="md" className={classes.container}>
      <div className={classes.table}>
        <h2 className={classes.header}>{data.category}</h2>
        {grid}
      </div>
    </Card>
  );
};

export default MethodologyCard;
