import React, { Fragment, useRef, useState } from 'react';

import {
  Card,
  Image,
  Text,
  Group,
  Anchor,
  useMantineTheme,
} from '@mantine/core';
import useStyles from './ProjectDetails.style';
import utilities from '../../../utils/utilities';
import { IconExternalLink } from '@tabler/icons';

interface ITeamAndBusinessProps {
  data: {
    logo?: string;
    cover?: string;
    title: string;
    description: string;
    website?: string;
  };
}

const ProjectDetails = ({ data }: ITeamAndBusinessProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const coverPlaceholderRef = useRef<any>();
  const [errors, setError] = useState({
    loadCover: false,
    loadLogo: false,
  });
  const [coverPlaceholderColor, setCoverPlaceholderColor] = useState('');

  return (
    <Card withBorder p="lg" className={classes.card}>
      <Card.Section>
        {data.cover && !errors.loadCover ? (
          <Image
            src={data.cover}
            alt={`${data.title} Logo`}
            height={250}
            onError={() =>
              setError(prevState => ({ ...prevState, loadCover: true }))
            }
          />
        ) : (
          <div
            ref={coverPlaceholderRef}
            className={classes.coverPlaceholder}
            style={{
              backgroundColor: coverPlaceholderColor
                ? coverPlaceholderColor
                : theme.colorScheme === 'dark'
                  ? theme.colors.dark[8]
                  : theme.colors.gray[0],
            }}
          />
        )}
      </Card.Section>

      <div className={classes.logoWrapper}>
        <div className="logo-container">
          <Image
            src={data.logo}
            width={120}
            height={120}
            onLoad={(e: any) => {
              const avgColor = utilities.getAverageColor(e.target);
              const { r, g, b } = avgColor || {};

              if (coverPlaceholderRef.current && avgColor) {
                setCoverPlaceholderColor(`rgb(${r}, ${g}, ${b})`);
              }
            }}
            onError={() =>
              setError(prevState => ({ ...prevState, loadLogo: true }))
            }
            withPlaceholder
          />
        </div>
        <div className="frame" />
      </div>

      <Group position="apart" mt="xl">
        <Text className={classes.title}>
          {data.website ? (
            <Anchor href={data.website} color="dimmed">
              <Group align="center" spacing={5}>
                <Text>{data.title}</Text>
                <IconExternalLink className="icon" size={20} />
              </Group>
            </Anchor>
          ) : (
            <Fragment>{data.title || 'No title'}</Fragment>
          )}
        </Text>
      </Group>

      <Text mt="sm" mb="md" color="dimmed" size="xs">
        {data.description || 'No description'}
      </Text>
    </Card>
  );
};

export default ProjectDetails;
