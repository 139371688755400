import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SentinelProject } from '../sentinel.types';

export const BASE_URL =
  process.env.REACT_APP_API_URL ||
  'https://developed-api-sentinel.headstarter.org';

export const awsApi = createApi({
  reducerPath: 'awsApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: builder => ({
    getProjects: builder.query<SentinelProject[], void>({
      query: () => {
        return {
          url: '/projects',
          method: 'GET',
        };
      },
      transformResponse: (response: SentinelProject[]) => {
        return response.filter(project => project.visible !== false);
      },
    }),
    getProjectById: builder.query<SentinelProject, string>({
      query: projectId => {
        return {
          url: `/projects/${projectId}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useGetProjectsQuery, useGetProjectByIdQuery } = awsApi;
