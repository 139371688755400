import { createStyles } from '@mantine/core';

const useStyle = createStyles(theme => ({
  container: {
    maxWidth: '1100px',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing.xl * 8,
    paddingTop: theme.spacing.xl * 2,
  },

  outer: {},

  inner: {
    display: 'flex',
    justifyContent: 'center',

    '&.full-height': {
      minHeight:
        'calc(100vh - var(--mantine-header-height) - var(--mantine-footer-height) - 48px)',
    },

    '&.column': {
      flexDirection: 'column',
      gap: '2rem',
    },
  },

  title: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.gray[0]
        : theme.colors.gray[9],
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 44,
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan('xs')]: {
      fontSize: 28,
    },
  },

  highlight: {
    position: 'relative',
    backgroundColor: theme.fn.variant({
      variant: 'light',
      color: theme.primaryColor,
    }).background,
    borderRadius: theme.radius.sm,
    padding: '4px 12px',
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default useStyle;
