import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Group,
  ScrollArea,
  Table,
  Tooltip,
  Avatar,
  Badge,
  Center,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { IconCross, IconInfoCircle } from '@tabler/icons';
import { SentinelProject } from '../../sentinel.types';

import Utils from '../../utils/utilities';
import useStyles from './ProjectRatings.style';

interface ITableReviewsProps {
  data: Array<SentinelProject>;
}

const ProjectRatings = ({ data }: ITableReviewsProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const handleClick = (project: SentinelProject) => {
    navigate(`/details/${project.projectId}`);
  };

  const rows = data.map((project, index) => {
    const isTokenHTS =
      project.tokenParameters.find(({ id }) => id === '2.1') ||
      project.tokenParameters[0];
    const tokenType =
      isTokenHTS?.answer === 'N/A'
        ? '-'
        : isTokenHTS?.answer === 'Yes'
          ? 'HTS'
          : 'ERC20';

    const categories = (project.category || '-')
      .split(',')
      .map(category => category.trim());

    const reviewDate = (
      <Group spacing={8}>
        <span>{Utils.getUtcDate(project.reviewDate)}</span>
        <Tooltip
          label={
            <div className={classes.tooltipLabel}>
              <span className="title">Local time:</span>
              <div className="local-date">
                <span>{Utils.getLocalDate(project.reviewDate)}</span>
                <span>{Utils.getUserTimezone()}</span>
              </div>
            </div>
          }
          color={theme.colorScheme === 'dark' ? 'gray' : 'dark'}
          withArrow
        >
          <div className={classes.infoIconWrapper}>
            <IconInfoCircle size={16} />
          </div>
        </Tooltip>
      </Group>
    );

    return (
      <tr key={`${project.name}-${index}`} onClick={() => handleClick(project)}>
        <td className="name">
          <Group align="center">
            <Avatar size={26} src={project.logo} radius={26} color={'cyan'}>
              {project.name[0]}
            </Avatar>
            {project.name || '-'}
            {project.isActive === false && (
              <Tooltip label="This project was confirmed to be a SCAM and has Rugpulled.">
                <Center>
                  <IconCross color={theme.colors.red[8]} size={20} />
                </Center>
              </Tooltip>
            )}
          </Group>
        </td>
        <td className="category">
          {categories.map(category => (
            <Badge
              key={`category-${category}`}
              className={classes.badge}
              variant="gradient"
              gradient={{ from: 'blue', to: 'indigo' }}
            >
              {category || '-'}
            </Badge>
          ))}
        </td>
        <td className="token-type">
          <Text>{tokenType || '-'}</Text>
        </td>
        <td className="review-date">{project.reviewDate ? reviewDate : '-'}</td>
      </tr>
    );
  });

  return (
    <ScrollArea>
      <Table
        className={classes.ratingsTable}
        sx={{ minWidth: 800 }}
        verticalSpacing="xs"
      >
        <thead>
          <tr>
            <th>Project Name</th>
            <th>Category</th>
            <th>Token Type</th>
            <th>Review Date (UTC)</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
};

export default ProjectRatings;
