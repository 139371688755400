import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  outer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default useStyles;
