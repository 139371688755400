import { createStyles } from '@mantine/core';

const useStyles = createStyles(theme => ({
  wrapper: {
    width: '100%',
    padding: `${theme.spacing.xl * 2}px 0 ${theme.spacing.xl * 2}px 0`,
    minHeight: 650,
  },

  title: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 600,
    marginBottom: theme.spacing.xl * 1.5,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.md,
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  question: {
    fontSize: theme.fontSizes.sm,

    P: {
      margin: `${theme.spacing.xs}px 0`,
    },
  },

  answer: {
    whiteSpace: 'break-spaces',
    fontSize: theme.fontSizes.sm,
  },
}));

export default useStyles;
