import { IHeaderSimpleProps } from '../components/header/Header';
import { IFooterSimpleProps } from '../components/footer/Footer';
import {
  IconBrandDiscord,
  IconBrandTwitter,
  IconBrandReddit,
  IconBrandTelegram,
} from '@tabler/icons';
import { ReviewAnswerStatus } from '../sentinel.types';

export const RATING_TABLE_VISIBLE = true;
export const APPLICATION_FORM_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSdQmZz6KVtaHQGcs9OJUi1n6MQdi1AgYL86kqmKuT5zaIVpUQ/viewform';
export const MIRROR_BASE_URL = 'https://mainnet-public.mirrornode.hedera.com';

export const HEADER_LINKS: IHeaderSimpleProps = {
  links: [
    { link: '/tokens', label: 'Tokens' },
    { link: '/methodology', label: 'Methodology' },
    { link: '/faq', label: 'FAQ' },
  ],
};

export const FOOTER_LINKS: IFooterSimpleProps = {
  socialMediaLinks: [
    {
      href: 'https://discord.com/invite/KzKqvVADGM',
      icon: IconBrandDiscord,
    },
    {
      href: 'https://twitter.com/headstarterorg',
      icon: IconBrandTwitter,
    },
    {
      href: 'https://www.reddit.com/r/HeadStarter',
      icon: IconBrandReddit,
    },
    {
      href: 'https://t.me/headstarterorg',
      icon: IconBrandTelegram,
    },
  ],
};

export const PROJECT_ANSWER_STATUS: {
  [key: number]: { label: string; color: string };
} = {
  [ReviewAnswerStatus.UNDER_REVIEW]: {
    label: 'In Review',
    color: 'yellow',
  },
  [ReviewAnswerStatus.REVIEW_COMPLETED]: {
    label: 'Reviewed',
    color: 'green',
  },
  [ReviewAnswerStatus.REVIEW_FAILED]: {
    label: 'Review Failed',
    color: 'red',
  },
  [ReviewAnswerStatus.FETCHED]: {
    label: 'Mirror',
    color: 'blue',
  },
};

export const FAQ_QUESTIONS = [
  {
    question: 'Why Sentinel?',
    answer:
      'The consistent developments in the Hedera protocol SDK & Ecosystem dApps have contributed to an explosion in network activity and TVL. This has led to a large gap in education when it comes to best practices for interacting with Web3 dApps and getting accustomed to the unique suite of native services Hedera is offering. An unbiased, objective, and public report on projects that deploy tokenized assets on the DLT is much needed to give support to community members through their “DYOR” process, and strongly benefit their decision making when contemplating interacting with a token and its infrastructure. \n\nThe Sentinel project has been born out of the demand for this need. It has been made possible through a joint push from Saucerswap & Stader, who along with HeadStarter co-founded the Hashgraph DeFi Alliance, in an effort to reduce illicit activity when interacting with DeFi protocols and protecting the community and networks users. ',
    id: 'why-sentinel',
  },
  {
    question: 'Wen Sentinel?',
    answer:
      'The project is rolling out a Beta version to serve as a key research resource for all Hedera users. \n\nThe methodology is public and will be subject to possible improvements and automation. Details can be reviewed and amended as projects evolve. All projects metrics consist of a timestamp reference for the report. A scoring system is being developed to serve as an additional reference point. ',
    id: 'wen-sentinel',
  },
  {
    question: 'How Sentinel?',
    answer:
      'The HeadStarter team engage in project reviews, due diligence, and manual reviews for all aspects of the project & associated token (ad-hoc and on-demand), through the application process. All report entries contain a public reference to the decision justification. ',
    id: 'how-sentinel',
  },
  {
    question: 'What are the objectives of Sentinel?',
    answer:
      'The methodology is intended to help identify objective key aspects that must be considered by all decentralized network participants when researching projects. It is to serve as an educational vehicle for the community and projects alike. Key objectives include: \n- Reducing scams \n- Identifying risk factors \n- Encouraging best practices',
    id: 'objectives-sentinel',
  },
  {
    question: 'What is the Hashgraph DeFi Alliance?',
    answer:
      'The overall aim of the Alliance is to provide a cohesive and holistic approach that will help guide and streamline the efforts of project developments on Hedera. \n\nSaucerswap Labs, Stader Hedera and HeadStarter, the first 3 DeFi protocols natively built on Hedera reiterate their commitment to the Layer-1 DLT’s adoption through the formation of a symbiotic alliance with the commitment to drive ecosystem growth across multiple verticals.',
    id: 'alliance-sentinel',
  },
];

export const CATEGORIES = [
  'HEALTHCARE',
  'DEFI',
  'MEME',
  'MEME TOKEN',
  'NFT',
  'RWA TOKENIZATION',
  'REWARD TOKEN',
  'DEFI - LAUNCHPAD',
  'DAO',
  'GAMEFI',
  'STABLECOIN',
  'DEFI - DEX',
  'NFT MARKETPLACE',
  'LOYALYTY TOKEN',
  'MUSIC NFT MARKETPLACE',
];

export const PROJECT_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};
