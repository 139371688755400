import { createStyles } from '@mantine/core';

const useStyles = createStyles(theme => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: `${theme.spacing.md}px`,
    padding: `${theme.spacing.md}px ${theme.spacing.md}px`,
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.lg,
      marginBottom: theme.spacing.sm,
    },
  },

  logoContainer: {
    maxWidth: '120px',
  },
}));

export default useStyles;
