import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, Loader } from '@mantine/core';
import useStyles from './DetailsPage.style';

import { METHODOLOGY } from '../../data/methodology';

import ProjectDetails from '../../components/cards/project-details/ProjectDetails';
import MethodologyCard from '../../components/cards/methodology-card/MethodologyCard';
import ProjectStats from '../../components/cards/project-stats/ProjectStats';
import { ProjectParameters, TokenInfo } from '../../sentinel.types';
import { getTokenParams } from '../../utils/getTokenParams';
import TokenHoldersTable from '../../components/token-holders-table/TokenHoldersTable';
import SocialStats from '../../components/cards/project-stats/SocialStats';
import { useGetProjectByIdQuery } from '../../APIs/Aws.api';
import { useLazyGetProjectQuery } from '../../APIs/MirrorNode.api';

interface IDetailsPageState {
  loading?: boolean;
  tokenInfo: TokenInfo;
}

const DetailsPage = () => {
  const { classes } = useStyles();
  const { id } = useParams();
  const { data: currentProject, isLoading } = useGetProjectByIdQuery(
    id as string
  );

  const [getToken] = useLazyGetProjectQuery();

  const [state, setState] = useState<IDetailsPageState | null>(null);

  useEffect(() => {
    if (!currentProject) return;

    const getTokenInfo = async () => {
      const res = await getToken(currentProject.token.id);
      const project = res.data;
      try {
        if (project) {
          const tokenInfo: TokenInfo = {
            tokenId: currentProject.token.id.trimEnd(),
            name: project.name,
            symbol: project.symbol,
            adminKey: project.admin_key,
            freezeKey: project.freeze_key,
            wipeKey: project.wipe_key,
            supplyKey: project.supply_key,
            feeScheduleKey: project.fee_schedule_key,
            pauseKey: project.pause_key,
            supplyType: project.supply_type,
            totalSupply: project.total_supply,
            decimals: project.decimals,
            isActive: currentProject.isActive,
            custom_fees: project.custom_fees,
          };

          setState(prevState => ({
            ...prevState,
            loading: false,
            tokenInfo,
          }));
        }
      } catch (e) {
        console.error(e);

        setState(prevState => ({
          ...(prevState as IDetailsPageState),
          loading: false,
        }));
      }
    };

    getTokenInfo();
  }, [currentProject, getToken]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const renderCardsFor = (items: Array<Object>) => {
    return items.map((methodologyCategory: any, index: any) => {
      // @ts-ignore
      let answers = currentProject[methodologyCategory.id] ?? [];

      if (methodologyCategory.id === 'tokenParameters' && state?.tokenInfo) {
        // @ts-ignore
        const staticTokenParameters: ProjectParameters[] = currentProject[
          methodologyCategory.id
        ] as ProjectParameters[];

        if (staticTokenParameters) {
          answers = getTokenParams(state.tokenInfo, staticTokenParameters);
        }
      }

      return (
        <Grid.Col key={`grid-${index}`}>
          <MethodologyCard
            data={methodologyCategory}
            answers={answers}
            tokenParamsLoaded={state?.tokenInfo ? true : false}
          />
        </Grid.Col>
      );
    });
  };

  const splitMethodologyColumns = (candid: any) => {
    let oddItems: Array<Object> = [],
      evenItems: Array<Object> = [];

    for (let i = 0; i < candid.length; i++) {
      (i % 2 === 0 ? evenItems : oddItems).push(candid[i]);
    }

    return [evenItems, oddItems];
  };

  /**
   * In order to render the methodology cards on two columns, and preserve the categories order
   * we have to split the array in half.
   * Even index should be displayed on the left column, and Odd index on the right one.
   * */
  const [methodologyLeftCol, methodologyRightCol] =
    splitMethodologyColumns(METHODOLOGY);

  return isLoading ? (
    <Loader className={classes.loader} />
  ) : currentProject ? (
    <Container my="lg" className={classes.container}>
      <Grid gutter={20}>
        <Grid.Col xs={12} sm={6} lg={6}>
          <ProjectDetails
            data={{
              logo: currentProject.logo,
              cover: currentProject.cover,
              title: currentProject.name,
              website: currentProject.website,
              description: currentProject.description,
            }}
          />
          <Grid.Col xs={12} sm={6} lg={6} />
          {state && state.tokenInfo && (
            <SocialStats
              isLoading={state?.loading}
              social={currentProject.social}
            />
          )}
        </Grid.Col>
        <Grid.Col xs={12} sm={6} lg={6}>
          <ProjectStats
            isLoading={state?.loading}
            isActive={currentProject.isActive}
            data={{
              tokenInfo: state?.tokenInfo,
              token: currentProject.token,
            }}
          />
          <Grid.Col xs={12} sm={6} lg={6} />
          {state && state.tokenInfo ? (
            <TokenHoldersTable
              tokenID={state.tokenInfo.tokenId as string}
              totalSupply={state.tokenInfo.totalSupply}
            />
          ) : (
            <SocialStats
              isLoading={state?.loading}
              social={currentProject.social}
            />
          )}
        </Grid.Col>
      </Grid>

      <Grid p={0}>
        <Grid.Col xs={12} lg={6} p={0}>
          {renderCardsFor(methodologyLeftCol)}
        </Grid.Col>

        <Grid.Col xs={12} lg={6} p={0}>
          {renderCardsFor(methodologyRightCol)}
        </Grid.Col>
      </Grid>
    </Container>
  ) : null;
};

export default DetailsPage;
