import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MIRROR_BASE_URL } from '../utils/constants';
import { TokenBalances } from '../sentinel.types';

interface AccountDetails {
  key: {
    _type: string;
    key: string;
  };
  memo: string;
}

export const mirrorNodeApi = createApi({
  reducerPath: 'mirrorNodeApi',
  baseQuery: fetchBaseQuery({ baseUrl: MIRROR_BASE_URL }),
  endpoints: builder => ({
    getTokenBalances: builder.query<TokenBalances, [string, number]>({
      query: ([tokenID, totalSupply]: [string, number]) => {
        const minBalance = (totalSupply / 100).toFixed(0);

        return {
          url: `/api/v1/tokens/${tokenID}/balances?account.balance=gte:${minBalance}&limit=100&order=desc`,
          method: 'GET',
        };
      },
    }),
    getAccountDetails: builder.query<AccountDetails, string>({
      query: (account: string) => {
        return {
          url: `/api/v1/accounts/${account}`,
          method: 'GET',
        };
      },
    }),
    getProject: builder.query<any, string>({
      query: (projectId: string) => {
        return {
          url: `/api/v1/tokens/${projectId}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetTokenBalancesQuery,
  useGetAccountDetailsQuery,
  useLazyGetAccountDetailsQuery,
  useGetProjectQuery,
  useLazyGetProjectQuery,
} = mirrorNodeApi;
