import moment from 'moment';
import momentTimezone from 'moment-timezone';

const DEFAULT_DATE_FORMAT: string = 'MMMM Do YYYY, h:mm A';

const Utils = {
  getUtcDate: function (value: number | string, format = DEFAULT_DATE_FORMAT) {
    return moment(Number(value)).utc().format(format);
  },

  getLocalDate: function (
    value: number | string,
    format = DEFAULT_DATE_FORMAT
  ) {
    return moment(Number(value)).utc().local().format(format);
  },

  getUserTimezone: () => {
    const tz = momentTimezone.tz.guess();
    return momentTimezone.tz(tz).format('Z z');
  },

  /**
   * Source: https://stackoverflow.com/questions/2541481/get-average-color-of-image-via-javascript
   * */
  getAverageColor: (img: HTMLImageElement) => {
    const max = 10; // Max size (Higher num = better precision but slower)
    const { naturalWidth: iw, naturalHeight: ih } = img;
    const ctx = document.createElement('canvas').getContext('2d');
    const sr = Math.min(max / iw, max / ih); // Scale ratio
    const w = Math.ceil(iw * sr); // Width
    const h = Math.ceil(ih * sr); // Height
    const a = w * h; // Area

    if (ctx) {
      img.crossOrigin = 'Anonymous';
      ctx.canvas.width = w;
      ctx.canvas.height = h;
      ctx.drawImage(img, 0, 0, w, h);

      const data = ctx.getImageData(0, 0, w, h).data;
      let r = 0,
        g = 0,
        b = 0;

      for (let i = 0; i < data.length; i += 4) {
        r += data[i];
        g += data[i + 1];
        b += data[i + 2];
      }

      r = ~~(r / a);
      g = ~~(g / a);
      b = ~~(b / a);

      return { r, g, b };
    }
  },
};

export default Utils;
