import React, { useEffect, useState } from 'react';
import {
  Table,
  ScrollArea,
  Title,
  Container,
  Paper,
  Text,
  Anchor,
  Group,
  Stack,
  Loader,
} from '@mantine/core';
import useStyles from './TokenHoldersTable.style';
import { IconExternalLink } from '@tabler/icons';
import { formatBalance, getIcon, renderMemoTooltip } from './utils';
import {
  useGetTokenBalancesQuery,
  useLazyGetAccountDetailsQuery,
} from '../../APIs/MirrorNode.api';

export interface TokenHoldersProps {
  tokenID: string;
  totalSupply: number;
}
export interface TokenBalances {
  account: string;
  balance: number;
  decimals: number;
}
export interface AccountDetails {
  key: {
    _type: string;
    key: string;
  };
  memo: string;
}

const TokenHoldersTable: React.FC<TokenHoldersProps> = ({
  tokenID,
  totalSupply,
}) => {
  const { classes } = useStyles();
  const [accountDetails, setAccountDetails] = useState<any>([]);
  const { data: tokenBalances, isLoading } = useGetTokenBalancesQuery([
    tokenID,
    totalSupply,
  ]);

  const [getAccountDetails] = useLazyGetAccountDetailsQuery();

  useEffect(() => {
    if (tokenBalances) {
      const getDetails = async () => {
        const topAccounts = tokenBalances.balances.slice(0, 10);
        const accountDetails = await Promise.all(
          topAccounts.map(async account => {
            const res = await getAccountDetails(account.account);
            return res.data;
          })
        );

        setAccountDetails(accountDetails);
      };

      getDetails();
    }
  }, [tokenBalances, getAccountDetails]);

  return (
    <Container p={0}>
      <Paper className={classes.infoCard} withBorder p="md" radius="md">
        <div className={classes.item}>
          <Title className={classes.header} align="center" order={3}>
            Top Token Holders
          </Title>

          <ScrollArea style={{ height: 380, overflowX: 'auto' }}>
            {isLoading ? (
              <Loader size="md" className={classes.loader} />
            ) : (
              <Table
                striped
                className={classes.table}
                verticalSpacing="md"
                highlightOnHover
              >
                <thead className={classes.tableHeader}>
                  <tr>
                    <th className={classes.tableColumn}>Account ID</th>
                    <th>Balance</th>
                    <th>Holder Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {tokenBalances &&
                    tokenBalances.balances.map((balance, index) => (
                      <tr key={index}>
                        <td className={classes.tableColumn}>
                          <Group spacing={10} noWrap>
                            {index < 10 &&
                              accountDetails[index] &&
                              renderMemoTooltip(accountDetails[index].memo)}
                            <Stack spacing={3}>
                              <Anchor
                                href={
                                  accountDetails[index] &&
                                  accountDetails[index].key
                                    ? `https://hashscan.io/mainnet/account/${balance.account}`
                                    : `https://hashscan.io/mainnet/contract/${balance.account}`
                                }
                                className={classes.anchor}
                                target="_blank"
                              >
                                <Group spacing={5} noWrap>
                                  <Text className={classes.value}>
                                    {balance.account}
                                  </Text>

                                  <IconExternalLink
                                    className={classes.icon}
                                    size={16}
                                  />
                                </Group>
                              </Anchor>

                              {index < 10 &&
                                accountDetails[index] &&
                                getIcon(accountDetails[index])}
                            </Stack>
                          </Group>
                        </td>
                        <td>
                          <Text className={classes.value}>
                            {formatBalance(balance.balance, balance.decimals)}
                          </Text>
                        </td>
                        <td>
                          <Text className={classes.value}>
                            {((balance.balance / totalSupply) * 100).toFixed(2)}
                            %
                          </Text>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
          </ScrollArea>
        </div>
      </Paper>
    </Container>
  );
};

export default TokenHoldersTable;
